@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #3A837C;
  --secondary-color: #FFFFFF;
}

.text-primary {
  color: var(--primary-color);
}

.text-secondary {
  color: var(--secondary-color);
}

.bg-primary {
  background-color: var(--primary-color);
}

.bg-secondary {
  background-color: var(--secondary-color);
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.collapsible {
  font-size: 24px;
  font-weight: 600;
  margin-top: 16px;
  text-align: left;
  color: #3A837C;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e7eb;
}

.contentblock {
  padding: 1.5rem;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  text-align: left;
  transition: transform 0.7s;
}

.contentblock:hover {
  transform: scale(1.01);
  /* Equivalent to hover:scale-[101%] */
}

/* Media queries */
/* 
@media (max-width: 640px) {

  .logo {
    display: none;
  }
} */